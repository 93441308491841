import firebase from "firebase";

import { isBrowser, isObjectEmpty, b64toBlob } from "services/general";

export const uploadDocumentsToStorage = async ({
	documents,
	path,
	docBaseName,
	docType,
}) => {
	let fileUrlsArray = [];

	if (isBrowser() && !isObjectEmpty(documents)) {
		let uploadedFiles =
			documents?.filter((document) =>
				document?.name?.toLowerCase()?.startsWith(docType)
			) || documents[0];

		let storageRef = firebase.storage().ref();

		let index = 0;
		for (const uploadedFile of uploadedFiles) {
			index += 1;
			let uploadRef = storageRef.child(
				`${process.env.GATSBY_WEBSITE_URL}${path}${docBaseName}_${uploadedFile.name}_${index}`
			);
			let b64Data = uploadedFile?.path?.split(",")[1];
			let contentType = uploadedFile?.path?.split(",")[0]?.match(/:(.*?);/)[1];
			let newUploadedFile = b64toBlob(b64Data, contentType);
			let storageResponse = await uploadRef.put(newUploadedFile);
			let fileURL = await storageResponse.ref.getDownloadURL();

			fileUrlsArray.push({
				type: docType.toUpperCase(),
				url: fileURL,
			});
		}
		return fileUrlsArray;
	}
};
