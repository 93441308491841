import axios from "axios";

const zendeskApiKey = process.env.GATSBY_MH_ZENDESK_API_KEY;
const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST]";

export const zendeskApi = () => {
	let email = process.env.GATSBY_ZENDESK_EMAIL;
	let apiKey = zendeskApiKey;
	let apiUrl = process.env.GATSBY_ZENDESK_API_URL;
	let zendeskKey = email + "/token:" + apiKey;
	let encryptedKey = Buffer.from(zendeskKey).toString("base64");
	let api = axios.create({
		baseURL: apiUrl,
		headers: {
			Authorization: "Basic " + encryptedKey,
			"Content-Type": "application/json",
		},
	});

	return api;
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;
	let byteCharacters = atob(b64Data);
	let byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize);
		let byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		let byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	let blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const generateUploadFiles = (uploadedFiles) =>
	uploadedFiles.map((response) => {
		return response.data.upload.token;
	});

export const checkFileSize = (fileArray) => {
	const MAX_SIZE = 10485760;
	for (let i = 0; i < fileArray.length; i++) {
		if (fileArray[i].path.size >= MAX_SIZE) {
			let errorMessage = {
				code: 403,
				message: "File is too big to attach in zendesk.",
			};
			throw errorMessage;
		}
	}
};

export const sendToZendesk = async ({ request }) => {
	const comment = { ...request.comment };
	const requestTemplate = { request: { ...request, comment } };

	return await zendeskApi().post("/requests.json", requestTemplate);
};
export const zendeskUploadFiles = (fileArray, callback, zendeskConfig) =>
	axios.all(
		fileArray.map((file) => {
			const b64Data = file.path.split(",")[1];
			const contentType = file.path.split(",")[0].match(/:(.*?);/)[1];
			return zendeskApi({
				...zendeskConfig,
			}).post(
				`/uploads.json?filename=${file.filename}`,
				b64toBlob(b64Data, contentType),
				{ headers: { "Content-Type": "application/binary" } }
			);
		})
	);

export const generateTemplate = (
	subject,
	email,
	template,
	templateObjects,
	tags
) => {
	return {
		type: "request",
		subject: `${TEST_ENV} ${subject} ${email}`,
		requester: { name: email, email },
		comment: { body: template(templateObjects) },
		tags,
	};
};

export const generateZendeskTemplate = ({
	subject,
	email,
	template,
	templateObjects,
	tags = [],
	name,
	customFields = [],
	priority = "normal",
}) => {
	return {
		type: "request",
		subject: `${TEST_ENV} ${subject}`,
		requester: { name: name, email },
		comment: { body: template(templateObjects) },
		tags,
		priority: priority,
		custom_fields: customFields,
	};
};
